.copy-dialog-container {
  min-height: 500px;
  min-width: 500px;
}
.copy-item-name {
  flex: 30%;
}
.copy-item-field {
  flex: 1;
  padding: 5px;
}
.copy-dialog-image {
  height: 40px;
  width: 40px;
}
.copy-dialog-add {
  margin-left: 5px;
  margin-right: 5px;
}
.css-1daddhm-MuiDrawer-docked .MuiDrawer-paper {
  /* background-image: linear-gradient(to top, gray 0%, gray 100%); */
  background-image: linear-gradient(135deg, #dbdbdb 0%, #dbdbdb 100%);
  /* background-image: "gray"; */
}
