.css-19ygod6-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input:not(
    [multiple]
  )
  option {
  background-color: #c0c0c0 !important;
  font-size: 10px;
  /* border-bottom: 2px solid white !important;
  padding: 10px !important; */
}
th {
  border-bottom: 2px solid black;
  font-weight: normal;
  font-size: 15px;
  font-family: "Lato";
}
table {
  border-spacing: 0px;
}
td {
  font-family: "Lato";
  text-align: center;
  padding-top: 6px;
  font-size: 13px;
}
.css-19ygod6-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input {
  font-size: 13px !important;
}
.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  background-color: white;
}
