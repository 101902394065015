.form-component-container {
  padding: 0.5rem;
  width: 100%;
}
.user-input-container {
  padding: 1.5rem;
}
.user-input-items {
  margin-left: 0.9rem;
  margin-right: 0.9rem;
}
.form-fields-container {
  margin-top: 0.7rem;
  padding: 1.5rem;
}
.invoice-form-grid-item {
  /* width: 100% */
}
.invoice-form-fields {
  /* width:300px */
}
.cu-card {
  box-shadow: none !important;
}
.second-row-items {
  margin-top: 1.7rem;
  margin-bottom: 1.7rem;
  background-color: aliceblue;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding: 1rem;
}
.label-no {
  font-size: 1.2rem;
  font-weight: bolder;
  font-family: "Lato";
}
.label-name {
  font-size: 0.9rem;
  font-weight: bolder;
}
